
<div class="loginbody">
    <div>
        <mat-card class="login-card mat-elevation-z8 p-4 ">
            <form [formGroup]="loginForm">
                <mat-card-content>
                    <div class="d-flex justify-content-center mb-2">
                        <img src="assets/logo/login-logo.png" />
                    </div>
                    <p class="Forgotstyle"> {{"Forgot Your Password"|translate}}</p>
                    <p> {{"Please enter the email address you'd like your password reset information sent to"|translate}}</p>
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-icon matPrefix>email</mat-icon> 
                        <mat-label>{{'Enter email address'|translate}}</mat-label>
                        <input matInput type="text"  formControlName="email">
                        <mat-error *ngIf="loginForm.controls['email'].hasError('required')">
                            {{'Email is required'|translate}}
                        </mat-error>
                    </mat-form-field>
                </mat-card-content>
                <mat-card-actions >
                    <button mat-raised-button [disabled]="!loginForm.valid" (click)="onSubmit()"
                        [ngClass]="loginForm.valid==true? 'b-blue' : 'g-blue'"
                        class="w-100">{{'Request reset link'|translate}}</button>
                        <p (click)="backtologin()" style="text-align: center !important;margin-top:15px;color: blue;cursor: pointer;">{{"Back To Login"|translate}}</p>
                </mat-card-actions>
            </form>
        </mat-card>
    </div>
</div>