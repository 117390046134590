<div class="d-flex flex-row align-items-center modal-header">
    <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">
        <span> <mat-icon>person</mat-icon>{{'Export File'|translate}}</span>
    </h2>
</div>
<mat-dialog-content class="mr-0 ml-0">
    <form [formGroup]="userForm">
            <mat-form-field class="w-100">
                <mat-label>{{"File Name"|translate}}</mat-label>
                <input matInput formControlName="FileName">
            </mat-form-field>
        <mat-form-field class="w-100" >
            <mat-label><mat-icon>date_range</mat-icon> Choose a Drive *</mat-label>
            <mat-select formControlName="DriveType" >
                <mat-option value="GoogleDrive" ><img class="drowdownimage"
                        src="../../../assets/images/iconsForsvg/google-drive-svgrepo-com.svg">
                    <b>Google Drive</b></mat-option>
                <mat-option value="Onedrive" ><img class="drowdownimage"
                        src="../../../assets/images/iconsForsvg/ms-onedrive-svgrepo-com.svg">
                    <b>Onedrive </b></mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end" class="my-1">
    <div class="row mt-2 justify-content-center">
        <div class="col-4">
            <button mat-raised-button mat-dialog-close class="W-blue">{{"Cancel"|translate}}</button>
        </div>
        <div class="col-4">
            <button mat-raised-button [disabled]="!userForm.valid" class="w-100 b-blue"  (click)="onSubmit()">{{"Apply"|translate}}</button>
        </div>
    </div>
</mat-dialog-actions>