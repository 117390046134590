<div class="d-flex flex-row align-items-center modal-header">
    <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">
        <mat-icon style="position: relative;right: 10px;"> group</mat-icon>
        {{'Update Dynamic Group'|translate}}
    </h2>
    <mat-icon mat-dialog-close>close</mat-icon>
</div>

<mat-dialog-content class="mr-0 ml-0">
    <form [formGroup]="DynamicForm" id="add-notes-form">
        <div class="row">
            <div class="col-12">
                <!-- title -->
                <mat-form-field  class="w-100">
                    <mat-label>{{'Group Name'|translate}}</mat-label>
                    <input matInput  formControlName="name">
                </mat-form-field>
            </div>
            <div class="col-12">
                <!-- description  -->
                <mat-form-field  class="w-100">
                    <mat-label>{{'Description'|translate}}</mat-label>
                    <input matInput  formControlName="description">
                </mat-form-field>
            </div>
            <div class="col-12">
                <mat-form-field class="example-chip-list" style="width: 100% !important;">
                    <mat-label>{{'keyword'|translate}}</mat-label><br>
                    <mat-chip-list #chipGridforkeyword aria-label="Fruit selection" class="keywordlist">
                        <mat-chip-row class="keywordrow" *ngFor="let keyword of keywordlist">
                            <button class="keywordbutton">
                                <span class="keywordpostion">{{keyword|titlecase}}</span>
                                <mat-icon class="keywordcancelicon" (click)="remove(keyword)">cancel</mat-icon>
                            </button>
                        </mat-chip-row>
                    </mat-chip-list>
                    <input  [matChipInputFor]="chipGridforkeyword"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="add($event)" />
                </mat-form-field>
            </div>
        </div>
        <div class="row condictionbodystyle">
            <div class="col-10"> <h4 style="text-align: center;"><b>Dynamic Group Conditions</b></h4> </div>
            <div class="col-2">
                <mat-icon (click)="Addcondictuon()">add_box</mat-icon>
            </div> 
        </div>
        <div class="row" formArrayName="dynamicGroupConditions" style="border: 1px solid gray;">
            <div class="row" *ngFor="let file of dynamicGroupConditions().controls; let i=index" [formGroupName]="i">
                <div class="col-4">
                    <!-- selectName -->
                    <mat-form-field  class="w-100">
                        <mat-label>{{'Parameter'|translate}}</mat-label>
                        <mat-select formControlName="conditionParameter" >
                            <mat-option [value]="x.columnName" *ngFor="let x of matchValue" (click)="selactdatatype(x,i)">
                                {{x.columnName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-4">
                    <!-- AllOption  -->
                    <ng-container
                        *ngIf="getdataType(i)!='string'&&getdataType(i)!='String' && getdataType(i)!='number'&&getdataType(i)!='Number'
                         && getdataType(i)!='bool' &&getdataType(i)!='boolean' &&getdataType(i)!='text'">
                        <mat-form-field  class="w-100">
                            <mat-label>{{'Comparison'|translate}}</mat-label>
                            <mat-select formControlName="conditionComparison">
                                <mat-option [value]="x.v1" *ngFor="let x of AllOption">
                                    {{x.v2|translate}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-container>
                    <!-- string -->
                    <ng-container
                        *ngIf="getdataType(i)=='string' || getdataType(i)=='String' || getdataType(i)=='text'">
                        <mat-form-field  class="w-100">
                            <mat-label>{{'Comparison'|translate}}</mat-label>
                            <mat-select formControlName="conditionComparison">
                                <mat-option [value]="x.v1" *ngFor="let x of stringoptions">
                                    {{x.v2|translate}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-container>
                    <!-- number  -->
                    <ng-container *ngIf="getdataType(i)=='number' || getdataType(i)=='Number'">
                        <mat-form-field  class="w-100">
                            <mat-label>{{'Comparison'|translate}}</mat-label>
                            <mat-select formControlName="conditionComparison">
                                <mat-option [value]="x.v1" *ngFor="let x of numberoptions">
                                    {{x.v2|translate}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-container>
                    <!-- bool  -->
                    <ng-container
                        *ngIf="getdataType(i)=='bool' || getdataType(i)=='boolean'|| getdataType(i)=='Boolean'|| getdataType(i)=='Bool'">
                        <mat-form-field  class="w-100">
                            <mat-label>{{'Comparison'|translate}}</mat-label>
                            <mat-select formControlName="conditionComparison">
                                <mat-option [value]="x.v1" *ngFor="let x of booleanoptions">
                                    {{x.v2|translate}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-container>
                </div>
                <div class="col-3">
                    <mat-form-field  class="w-100">
                        <mat-label>{{'value'|translate}}</mat-label>
                        <input matInput  formControlName="value">
                    </mat-form-field>
                </div>
            <ng-container *ngIf="i!=0">
                <div class="col-1">
                    <div class="row">
                        <div class="col">
                            <mat-icon (click)="Removecondictuon(i)">delete</mat-icon>
                        </div>
                    </div>
                </div>
            </ng-container>
            </div>
        </div>
        <!-- start end date  -->
        <ng-container *ngIf="showDate">
            <div class="col-12">
                <div class="row">
                    <div class="col-6">
                        <mat-form-field  class="w-100">
                            <mat-label>{{'Start Date'|translate}}</mat-label>
                            <input matInput [matDatepicker]="startdatepicker1" 
                                formControlName="startDate">
                            <mat-datepicker-toggle matSuffix [for]="startdatepicker1"></mat-datepicker-toggle>
                            <mat-datepicker #startdatepicker1></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field  class="w-100">
                            <mat-label>{{'End Date'|translate}}</mat-label>
                            <input matInput [matDatepicker]="enddatepicker1" 
                                formControlName="endDate">
                            <mat-datepicker-toggle matSuffix [for]="enddatepicker1"></mat-datepicker-toggle>
                            <mat-datepicker #enddatepicker1></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </ng-container>

    </form>
</mat-dialog-content>

<footer style="margin-bottom: 30px;">
    <mat-dialog-actions align="end" class="p-3">
        <div>
            <button mat-button mat-dialog-close>{{'Cancel'|translate}}</button>
            <button mat-raised-button (click)="onSubmit()" class="b-blue">{{'Update'|translate}}</button>
        </div>
    </mat-dialog-actions>
</footer>