<div class="d-flex flex-row align-items-center modal-header">
  <mat-icon mat-dialog-close>keyboard_backspace</mat-icon>
  <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">
    <span>  {{ActionName}} </span>
  </h2>
</div>
<mat-dialog-content class="mr-0 ml-0" style="min-height: 300px !important;">
  <form [formGroup]="form">
    <div class="row mt-2 mb-2">
      <div class="col-12">
        <mat-form-field class="w-100" >
          <mat-label>Subject</mat-label>
          <input matInput  formControlName="subject" placeholder="Subject">
        </mat-form-field>
      </div>
    </div>
    <div class="col-12">
        <mat-list-item>
            <mat-checkbox formControlName="isContact">
                {{'Would you like to import your contacts into the Planning&Me ?'|translate}}</mat-checkbox>
        </mat-list-item>
    </div>
  <div class="row ">
    <div class="col-12">
      <mat-form-field class="w-100" >
        <mat-label>Contact Email</mat-label>
        <input matInput type="email" formControlName="contactEmail" placeholder="contactEmail">
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field  class="w-100">
        <mat-label>{{"Date"|translate}}</mat-label>
        <input matInput [matDatepicker]="startdatepicker1" style="color: black;"
            formControlName="date">
        <mat-datepicker-toggle matSuffix [for]="startdatepicker1"></mat-datepicker-toggle>
        <mat-datepicker #startdatepicker1></mat-datepicker>
    </mat-form-field>
    </div>
  </div>

          <!-- <div class="row firstAndLastName">
            <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-xl-6">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>{{"Start Date"|translate}}</mat-label>
                    <input matInput [matDatepicker]="startdatepicker1" style="color: black;"
                        formControlName="StartDate">
                    <mat-datepicker-toggle matSuffix [for]="startdatepicker1"></mat-datepicker-toggle>
                    <mat-datepicker #startdatepicker1></mat-datepicker>
                    <mat-error *ngIf="form.controls['StartDate'].hasError('required')">
                        {{"Start Date"|translate}} is <strong>{{"required"|translate}}</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-xl-6">
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>{{"End Date"|translate}}</mat-label>
                <input matInput [matDatepicker]="enddatepicker1" style="color: black;"
                    formControlName="EndDate">
                <mat-datepicker-toggle matSuffix [for]="enddatepicker1"></mat-datepicker-toggle>
                <mat-datepicker #enddatepicker1></mat-datepicker>
                <mat-error *ngIf="form.controls['EndDate'].hasError('required')">
                    {{"End Date"|translate}} is <strong>{{"required"|translate}}</strong>
                </mat-error>
            </mat-form-field>
            </div>
        </div> -->
 
  </form>
  <div class="overlay" *ngIf="loader">
    <mat-progress-spinner class="spinner" [color]="color" [mode]="mode" [value]="value">
    </mat-progress-spinner>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end" class="my-1">
  <div class="row mt-2 justify-content-center">
      <div class="col-4">
          <button mat-raised-button mat-dialog-close class="W-blue">{{"Cancel"|translate}}</button>
      </div>
      <div class="col-4">
          <button mat-raised-button class="b-blue"
           (click)="Applusubmit()">{{"Apply"|translate}}</button>
      </div>
  </div>
</mat-dialog-actions>