import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-accordian-view',
  templateUrl: './accordian-view.component.html',
  styleUrls: ['./accordian-view.component.scss']
})
export class AccordianViewComponent implements OnInit {

  itemsList: string[] = ['FirstName', 'LastName', 'Gender', 'Position', 'PhoneNumber', 'Email', 'Address', 'City', 'Country', 'ZipCode', 'Created'];

  constructor(
    public dialogRef: MatDialogRef<AccordianViewComponent>
  ) { }

  ngOnInit(): void {
  }

  selectItem(item: string): void {
    this.dialogRef.close(item);
  }

}
