<div class="d-flex flex-row align-items-center modal-header">
  <h2 mat-dialog-title
    class="m-0 dialog-title text-center w-100">
    <mat-icon style="position: relative;right: 10px;">
      track_changes</mat-icon>
    {{'Target Details'|translate}}
  </h2>
  <mat-icon class="pointer" mat-dialog-close>close</mat-icon>
</div>
<mat-dialog-content class="mr-0 ml-0">
  <div class="row mt-2 mb-2">
    <div class="col-5">
    </div>
    <div class="col-2">
      <div style="display: flex;">
        <mat-icon style="font-size: 50px;color: green !important;">
          track_changes</mat-icon>
      </div>
    </div>
    <div class="col-5">
      <button
        style="display: flex;justify-content: end;position: relative;left: 50px;"
        [matMenuTriggerFor]="beforeMenu" class="mr-1 b-color">
        <mat-icon class="icon-options">more_vert</mat-icon>
      </button>
      <mat-menu #beforeMenu="matMenu" xPosition="before">
        <button mat-menu-item (click)="openEditdEventDialog(taskData?.id)">
          <mat-icon>edit</mat-icon> {{'Edit'|translate}}
        </button>
        <button mat-menu-item
          (click)="deleteDialogConfirmation(taskData,'deleteEvent')">
          <mat-icon>delete</mat-icon> {{'Delete'|translate}}
        </button>
      </mat-menu>
    </div>
  </div>
  <h2 
  style="text-align: center; align-items: center;margin-top: 30px !important; padding: 0 !important;">
    <span>
        <img class="avatar1" *ngIf="(target?.profile!='' &&target?.profile !=null) && (target?.isGroup != true && target.targetAssignee != 4 && target.targetAssignee != 3)" 
            style="cursor: pointer;" onError="this.src='assets/avatar.svg'"
            [src]="target?.profile ? target.profile:'assets/avatar.svg'"
            onError="this.src='assets/avatar.svg'">
            <span class="mb-2 f-weight" *ngIf="target.isGroup == true">
              <span *ngIf="target.dynamicGrpId ==null">
               <img style="border-radius: 50%;" width="30" height="30"
                  src="../../../../../assets/images/iconsForsvg/groupiconRed.svg">
          </span>
          <span *ngIf="target.dynamicGrpId !=null">
              <img style="border-radius: 50%;"width="30" height="30"
                  src="../../../../../assets/images/iconsForsvg/groupiconblue.svg">
          </span>
          </span>
          <span *ngIf="target.targetAssignee == 4 && target.isGroup == false">
            <img style="border-radius: 50%;"width="30" height="30"
                src="../../../../../assets/images/iconsForsvg/Site_Icon.svg">
        </span>
        <span *ngIf="target.targetAssignee == 3 && target.isGroup == false">
          <img style="border-radius: 50%;"width="30" height="30"
              src="../../../../../assets/images/iconsForsvg/icon_product.svg">
      </span>
            <span  *ngIf="(target?.profile == '' || target?.profile == null ) && (target?.isGroup != true && target.targetAssignee != 4 && target.targetAssignee != 3)" 
             [style.background]="getColor(1)"  style="color: white; padding: 8px;border-radius: 50%;position: relative;margin: 5px;right: 8px;">{{getuserFLnames(target?.name)}}</span> 
             <span style="margin-left: 5px;">{{target.name|titlecase}}</span>
    </span>
  </h2><br>

  <section class="detail-wrapper" style="font-size: 16px !important;">
    <mat-list-item>
      <div class="fw-600 row">{{'Progress'|translate}} :
        <span class="ml-3 mb-2 fw-600 color_grey" style="width: 300px;">
          <div class="row justify-content-center"
            style="position: relative;top:-16px;">
            <div class="col-12"
              style="text-align: center;">{{target.percentage + '%'}}</div>
            <div class="col-12">
              <mat-progress-bar class="w-100" [value]="target.percentage" [ngClass]="target.cssClass">
              </mat-progress-bar>
            </div>
          </div>
        </span></div>
    </mat-list-item>

    <mat-list-item *ngIf="target.targetTypeName == 'Orders'">
      <div class="fw-600"><b> {{'Target'|translate}}
          :</b>
        <span class="ml-3 fw-600 color_grey">{{transform(target.totalAchievedValue)}} &euro; / {{transform(target.targetValue)}}
          &euro;</span>
      </div><br>
    </mat-list-item>
    <mat-list-item *ngIf="target.targetTypeName != 'Orders'">
      <div class="fw-600"><b> {{'Target'|translate}}
          :</b>
        <span class="ml-3 fw-600 color_grey">{{target.totalAchievedDuration}} / {{target.targetDuration}}
        </span>
      </div><br>
    </mat-list-item>
    <mat-list-item>
      <div class="fw-600"><b><mat-icon>event</mat-icon>
          {{'Start date'|translate}} :</b>
        <span class="ml-3 fw-600 color_grey">{{target.startDate | date:'dd-MMM-yyyy H:mm'}}</span>
      </div><br>
    </mat-list-item>

    <mat-list-item>
      <div class="fw-600"><b><mat-icon>event</mat-icon> {{'End date'|translate}}
          :</b>
        <span class="ml-3 fw-600 color_grey">{{target.endDate | date:'dd-MMM-yyyy H:mm'}} </span>
      </div><br>
    </mat-list-item>

    <mat-list-item>
      <div class="fw-600"><b><mat-icon>event</mat-icon> {{'Created date'|translate}}
          :</b>
        <span class="ml-3 fw-600 color_grey">{{target.created | date:'dd-MMM-yyyy H:mm'}} </span>
      </div><br>
    </mat-list-item>


    <mat-list-item *ngIf="target.isPublic == true">
      <div class="fw-600"><b><img class="mr-2" src="../../../../../assets/images/iconsForsvg/Unlock.svg" alt="">{{'Public'|translate}}:</b>
        <span class="ml-3 fw-600 color_grey">
         <span>{{'Yes'|translate}}</span>
        </span>
      </div><br>
    </mat-list-item>
    <mat-list-item *ngIf="target.isPrivate == true">
      <div class="fw-600"><b> <mat-icon
            >lock</mat-icon>{{'Private'|translate}}:</b>
        <span class="ml-3 fw-600 color_grey">
         <span>{{'Yes'|translate}}</span>
        </span>
      </div><br>
    </mat-list-item>
    <mat-list-item *ngIf="target.isDelegate == true">
      <div class="fw-600"><b> <mat-icon class="green"
            >device_hub</mat-icon>{{'Private'|translate}}:</b>
        <span class="ml-3 fw-600 color_grey">
         <span>{{'Yes'|translate}}</span>
        </span>
      </div><br>
    </mat-list-item>

    <mat-list-item>
      <div class="fw-600"><b> {{'Target Type'|translate}}
          :</b>
        <span class="ml-3 fw-600 color_grey">
          <mat-icon style="color:rgb(100, 149, 237);" *ngIf="target.targetTypeName == 'Orders'">shopping_cart</mat-icon>
          <mat-icon *ngIf="target.targetTypeName == 'Events'">event</mat-icon>
          <mat-icon style="color:green" *ngIf="target.targetTypeName == 'Tasks'">assignment_turned_in</mat-icon>
          <mat-icon *ngIf="target.targetTypeName == 'Initiatives'">ballot</mat-icon>
          {{target.targetTypeName}}</span>
      </div><br>
    </mat-list-item>
    <mat-list-item>
      <div class="fw-600"><b> {{'Target Method'|translate}}
          :</b>
        <span class="ml-3 fw-600 color_grey">{{target.valueTypeName? target.valueTypeName: 'Amount'}}</span>
      </div><br>
    </mat-list-item>
    <mat-list-item *ngIf="target.valueTypeName == 'Amount'">
      <div class="fw-600"><b> {{'Target Amount'|translate}}
          :</b>
        <span class="ml-3 fw-600 color_grey">{{transform(target.targetValue)}} &euro;</span>
      </div><br>
    </mat-list-item>
    <mat-list-item *ngIf="target.valueTypeName == 'Count'">
      <div class="fw-600"><b> {{'Target Count'|translate}}
          :</b>
        <span class="ml-3 fw-600 color_grey">{{target.targetValue}} </span>
      </div><br>
    </mat-list-item>
    <mat-list-item *ngIf="target.valueTypeName == 'Duration'">
      <tr class="mt-2">
        <td  style="padding-left: 0px !important;"><b
            class="fw-600"> {{'Target Duration'|translate}}:</b></td>
        <td class="fw-600 color_grey">
          <span>{{target.targetDuration}}</span>
        </td>
      </tr><br>
    </mat-list-item>
    <mat-list-item>
      <tr class="mt-2">
        <td style="padding-left: 0px !important;"><b class="fw-600"> {{'Status'|translate}}:</b></td>
        <td>
          <div style="display: flex;">
            <div>
              <span
                [ngClass]="getStatusColorClass(target?.statusOfTransaction)">{{getstatus(target?.statusOfTransaction)}}</span>
            </div>
          </div>
        </td>
      </tr><br>
    </mat-list-item>

    <div *ngIf="target?.keywords != null">
      <div><b class="fw-600"> {{'keywords'|translate}}:</b>
        <span class="fw-600 color_grey ml-3"
          *ngFor="let x of target?.keywords ;let i=index">
          <button class="normalkeyword">
            <span>{{'#'+' '+x|titlecase}}</span>
          </button>
        </span>

      </div>

    </div><br>
    </section>
    <div class="overlay" *ngIf="loader">
      <mat-progress-spinner class="spinner" [color]="color" [mode]="mode"
        [value]="value">
      </mat-progress-spinner>
    </div>
  </mat-dialog-content>