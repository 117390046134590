<ng-container *ngIf="screenType=='loginpage'">
    <div class="d-flex flex-row align-items-center modal-header">
        <h2 mat-dialog-title style="text-align: start;" class="m-0 dialog-title w-100">Error</h2>
        <mat-icon mat-dialog-close>error</mat-icon>
    </div> 

    <mat-dialog-content class="viewDialog pading "
        style="padding: 0px  !important; margin: 0px !important;height:60px; width:auto;">
        <div class="row">
            <span style="margin-top: 14px; margin-left: 34px; ">{{messange|translate}}</span>
        </div>
    </mat-dialog-content>
    <div class="row justify-content-center" style="margin-bottom: 8px;">
        <div class="col-4">
            <button mat-raised-button mat-dialog-close>{{"Ok"|translate}}</button>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="screenType=='createApplication'">
    <div class="d-flex flex-row align-items-center modal-header">
        <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">
            <mat-icon style="position: relative;right: 10px;"> person</mat-icon>
            {{"Create Application"|translate}}
        </h2>
        <mat-icon mat-dialog-close>close</mat-icon>
    </div>
    <mat-dialog-content class="mr-0 ml-0">
        <div class="row justify-content-center mt-4 m-b-10">
            <div class="col-10">
                <mat-form-field class="w-100">
                    <input matInput type="text" [(ngModel)]="applicationname" placeholder="Application Name">
                </mat-form-field>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end" class="my-1">
        <div class="row" style="margin-bottom: 8px;">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6"></div>
            <button mat-button mat-dialog-close (click)="clear()">{{"Cancel"|translate}}</button>
            <button mat-raised-button class="b-blue" (click)="onSubmit()">{{"Apply"|translate}}</button>
        </div>

    </mat-dialog-actions>
</ng-container>