<div class="d-flex flex-row align-items-center modal-header-in-error ">
    <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">{{header|translate}}</h2>
    <mat-icon matDialogClose>close</mat-icon>
</div>
<mat-dialog-content class="mr-0 ml-0 p-0">
    <div class="row mt-2 mb-2 justify-content-start">
        <div class="col-12 p-0">
            <img style="margin: auto;" src="../../assets/logo/logo.png" class="imagestyle" >
        </div>
        <div class="col-12 mt-2">
            <h3 style="text-align: start; align-items: start;">{{message|translate}}</h3>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end" class="my-1">
    <div class="row justify-content-end">
        <ng-container *ngIf="DemoOrganationstatus">
            <div class="col-5">
                <button mat-raised-button mat-dialog-close class="b-blue">{{'Ok'|translate}}</button>
            </div>
        </ng-container>
        <ng-container *ngIf="trailUsers">
            <div class="col-6">
                <button mat-raised-button (click)="paymentCardPopup()" class="b-blue">{{'Pay for full usage'|translate}}</button>
            </div>
        </ng-container> 
      
    </div>
</mat-dialog-actions> 