<div class="m-0 p-0"
    style="height: 100vh;width: 100%;background-color: black !important;">
    <div class="row justify-content-center">
        <img class="imageiconstyle" src="../../../../../assets/email-succes/click.png">
    </div>
    <p class="thankyoulable"> {{"Thank You"|translate}} !</p>
    <div class="maincontent">
        <p class="lablefortitle">
            {{"Your email address has been verified. You can now access all the  features and benefits of"|translate}} Planning&Me
        </p>
    </div>
    <div class="row mb-4" style="background-color: black !important;">
        <div class="col-12 col-sm-12  col-md-12 col-lg-7 col-xl-7 justify-content-center justify-content-lg-end ">
            <div class="row mt-5 justify-content-center justify-content-lg-end">
                <button (click)="backtoOtherUserPassword()" mat-raised-button style="float: right;
                padding: 15px 50px;
                border-radius: 30px;
                font-size: 20px;
                font-weight: bold;
            " class="R-Redbutton">{{'Create your Credentials'|translate}}</button>
            </div>
            <div class=" row mt-4 justify-content-center justify-content-lg-end">
                <img style="float: right;" src="../../../../../assets/email-succes/pnm-logo.png" class="pnm-logo">
            </div>
        </div>
        <div class="col-1"></div>
        <div class="col-sm-12  col-md-12 col-lg-4 col-xl-4 mt-5" style="background-color: black !important;">
            <img class="ladyimage" src="../../../../../assets/email-succes/lady.png">
        </div>
    </div>
</div>

