<div class="d-flex flex-row align-items-center modal-header">
    <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">Add a Media</h2>
    <mat-icon mat-dialog-close>close</mat-icon>
</div>

<mat-dialog-content class="mr-0 ml-0">
    <div class="row mt-3">
        <div class="col-8">
            <img  class="drowdownimage" src="../../../../assets/notecreateicons/Gmail.svg"> 
            <span style="margin-left: 5px;"><b>{{"Gmail"|translate}}</b></span></div>
        <div class="col-4">
            <button mat-raised-button class="b-blue" (click)="Gmails()">
             {{"Sync"|translate}}</button>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-8">
            <img  class="drowdownimage" src="../../../../assets/notecreateicons/GoogleDrive.png"> 
            <span style="margin-left: 5px;"><b> {{"Google Drive"|translate}}</b></span></div>
        <div class="col-4">
            <button mat-raised-button class="b-blue" (click)="googledrive()">
             {{"Sync"|translate}}</button>
        </div>
    </div>
    <div class="row mt-3 mb-4">
        <div class="col-8">
            <img  class="drowdownimage" src="../../../../assets/notecreateicons/OneDrive.svg">    
             <span style="margin-left: 5px;"><b>{{"OneDrive"|translate}}</b></span></div>
        <div class="col-4">
            <button mat-raised-button class="b-blue" (click)="OneDrive()">
                 {{"Sync"|translate}}</button>
        </div>
    </div>
    <div class="row mt-3 mb-4">
        <div class="col-8">
            <img  class="drowdownimage" src="../../../../assets/notecreateicons/Outlook.svg">    
             <span style="margin-left: 5px;"><b>{{"OutLook"|translate}}</b></span></div>
        <div class="col-4">
            <button mat-raised-button class="b-blue" (click)="OutLook()">
                 {{"Sync"|translate}}</button>
        </div>
    </div>

</mat-dialog-content>


<div class="overlay" *ngIf="spinner">
    <mat-progress-spinner class="spinner" [color]="color" [mode]="mode" [value]="value">
    </mat-progress-spinner>
</div>