<div class="d-flex flex-row align-items-center modal-header">
    <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">{{title}}</h2>
    <mat-icon mat-dialog-close>close</mat-icon>
</div>

<div class="container">
    <div class="row justify-content-start">
        <div class="col-6 p-2">
            <button class="fw-600" mat-menu-item (click)="View()">
                <mat-icon style="color:#fb4444">visibility</mat-icon>{{"View"|translate}}
            </button>
            <button class="fw-600" mat-menu-item (click)="Downloadfile()">
                <mat-icon style="color:#528bf1;">download</mat-icon>{{"Download"|translate}}
            </button>
            <button class="fw-600" mat-menu-item (click)="backupfile()">
                <mat-icon style="color:#fb4444;">backup</mat-icon> {{"Upload in cloud Services"|translate}}
            </button>
            <button class="fw-600" mat-menu-item (click)="linkfile()">
                <mat-icon style="color:green">link</mat-icon>{{"Link"|translate}}
            </button>
        </div>
    </div>
</div>