<div class="d-flex flex-row align-items-center modal-header">
    <h2 mat-dialog-title
      class="m-0 dialog-title text-center w-100">
      {{'Select Value'}}
    </h2>
    <mat-icon class="pointer" mat-dialog-close>close</mat-icon>
  </div>
<mat-dialog-content class="mr-0 ml-0">
        <div class="row w-100">
            <div class="col-12" *ngFor="let item of itemsList">
              <mat-card class="mt-2" style="background: royalblue;padding: 10px;width: fit-content;cursor:pointer" (click)="selectItem(item)">
                <mat-card-content>
                  <p style="color: white;margin:-3px">{{ item }}</p>
                </mat-card-content>
              </mat-card>
            </div>
        </div>
    
</mat-dialog-content>
