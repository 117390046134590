<div class="d-flex flex-row align-items-center modal-header">
    <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">
        <img src="../../../../assets/images/iconsForsvg/share-square-white.svg">
        {{"Share"|translate}}
    </h2>
    <mat-icon mat-dialog-close>close</mat-icon>
</div>

<mat-dialog-content style="padding: 10px  !important; margin: 0px !important; width: 500px;">
    <h2 style="text-align: start; margin-top: 10px; margin-bottom: 10px;">{{"Copy Link"|translate}}</h2>
    <div class="row p-0 mt-3 mb-3">
        <div class="col-1 p-0">
            <img src="../../../../../assets/images/iconsForsvg/globle-icon.svg">
        </div>
        <div class="col-9 p-0">
            <input class="w-100" type="text" [value]="filesAndNoteShareLinlK" readonly>

        </div>
        <div class="col-2">
            <button class="copybutton" (click)="copyMessage(filesAndNoteShareLinlK)" value="click to copy">
                <img src="../../../../../assets/images/iconsForsvg/copy-icon.svg"> {{"Copy"|translate}}</button>
        </div>
    </div>

</mat-dialog-content>