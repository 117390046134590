import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FileService } from 'src/app/core/services/file.service';
import { ManageUsersService } from 'src/app/core/services/manage-users.service';
import { ProfilePickFormatService } from 'src/app/core/services/profile-pick-format.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { AdvanceSearchInActivityLogComponent } from '../advanceSearch/advance-search-in-activity-log/advance-search-in-activity-log.component';

@Component({
  selector: 'app-activit-log',
  templateUrl: './activit-log.component.html',
  styleUrls: ['./activit-log.component.scss']
})
export class ActivitLogComponent implements OnInit {
  ActivitLogdata:any=[]
  PageNo=1;
  PageCount=10
  Entity='';
  UserIds=[]
  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  spinnerLoader = false
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public spinner: SpinnerService,
    private injector: Injector,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    console.log(this.data)
    this.Entity=this.data.Entity
    this.spinnerLoader = true
    this.injector.get(ManageUsersService).GetActivityLog(this.PageNo,this.PageCount,this.Entity,this.data.data.id.toString()).subscribe((result:any)=>{
      if(result){
        this.spinnerLoader = false
        console.log(result)
        this.ActivitLogdata=result.dto_1
        console.log(this.ActivitLogdata.length)
      } 
    }, error => {
      this.spinnerLoader = false
    })
  }

  getColor(index:number) {
    return this.injector.get(ProfilePickFormatService).getColor(index)
  }
  getuserFLnames(value:any){
    return this.injector.get(ProfilePickFormatService).getuserFLnames(value)
  }

  opensearch(){
    const AdvanceSearch = this.dialog.open(
      AdvanceSearchInActivityLogComponent,
      {
        disableClose: true,
        width: '500px',
      }
    );
    
    AdvanceSearch.afterClosed().subscribe((result: any) => {
      if (result) {
      }
    })
  }

}
