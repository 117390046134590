<div class="d-flex flex-row align-items-center modal-header">
  <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">
    <mat-icon style="position: relative;right: 10px;">search</mat-icon>{{'Advance Search'|translate}}
  </h2>
  <mat-icon mat-dialog-close>close</mat-icon>
</div>

<mat-dialog-content style="padding: 0px  !important; margin: 0px !important; overflow: auto">
  <form [formGroup]="searchForm" style="margin: 10px;">
    <div class="row mt-2">
      <mat-radio-group formControlName="options" (change)="onchange($event.value)">
        <mat-radio-button [checked]="true" value="1">{{'Search in My Files & Notes'|translate}}</mat-radio-button>
        <mat-radio-button value="2">{{"Search in My Organization"|translate}}</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="row mt-3">
      <div class="col-5">
        <section>
          <mat-checkbox class="chck" formControlName="isFile">{{"Files"|translate}}</mat-checkbox>
        </section>
      </div>
      <div class="col-5">
        <section>
          <mat-checkbox class="chck" formControlName="isNote">{{"Notes"|translate}}</mat-checkbox>
        </section>
      </div>
    </div>
    <div class="row">
      <mat-form-field appearance="legacy" class="w-100">
        <mat-label>{{"Title"|translate}}</mat-label>
        <input matInput  formControlName="title" />
      </mat-form-field>
    </div>

    <div class="row">
      <mat-form-field appearance="fill" class="w-100 blue_bg">
        <mat-label>{{'Attachment Level'|translate}}</mat-label>
        <mat-select formControlName="attachmentLevel" (selectionChange)="onChanged($event)">
          <mat-option *ngFor="let type of managementType" [value]="type.name">
            {{ type.name|translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <ng-container *ngIf="selectedMgmt === 'user'">
      <div class="row justify-content-end">
        <div class="col-11">
          <!-- selectid  -->
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>{{'Users'|translate}}</mat-label>
            <mat-select  multiple formControlName="selectid">
              <mat-select-filter [placeholder]="'Filter'" [displayMember]="'firstName'" [array]="filterforuserlist"
                (filteredReturn)="filterforuserlist=$event"></mat-select-filter>
              <mat-option [value]="user.id" *ngFor="let user of filterforuserlist">
                <img style="border-radius: 50%; margin-right: 6px;" width="30" height="30"
                  [src]="user?.profilePicture ? user.profilePicture:'assets/avatar.svg'"
                  onError="this.src='assets/avatar.svg'">
                {{user.firstName|titlecase}} {{user.lastName|titlecase}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!-- select Group  -->
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>{{"User Groups"|translate}}</mat-label>
            <mat-select  multiple formControlName="selectGroup">
              <mat-select-filter [placeholder]="'Filter'" [displayMember]="'firstName'" [array]="filterforuserGrouplist"
                (filteredReturn)="filterforuserGrouplist =$event"></mat-select-filter>
              <mat-option [value]="user.id" *ngFor="let user of filterforuserGrouplist">
                <mat-icon [ngStyle]="{'color':(user.dynamicGroupId ==null)?'red':'blue'}"
                  class="m-r">group</mat-icon>{{user.userGroupName|titlecase}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="selectedMgmt == 'sites'">
      <div class="row justify-content-end">
        <div class="col-11">
          <!-- selectid  -->
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>{{'Sites'|translate}}</mat-label>
            <mat-select  multiple formControlName="selectid">
              <mat-select-filter [placeholder]="'Filter'" [displayMember]="'firstName'" [array]="filterforsiteslist"
                (filteredReturn)="filterforsiteslist=$event"></mat-select-filter>
              <mat-option [value]="site.id" *ngFor="let site of filterforsiteslist">
                <img src="../../../../../assets/images/iconsForsvg/Site_Icon.svg">
                {{site.companyName|titlecase}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!-- select site Group  -->
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>{{"Site Groups"|translate}}</mat-label>
            <mat-select  multiple formControlName="selectGroup">
              <mat-select-filter [placeholder]="'Filter'" [displayMember]="'firstName'" [array]="filterforsiteGrouplist"
                (filteredReturn)="filterforsiteGrouplist =$event"></mat-select-filter>
              <mat-option [value]="site.id" *ngFor="let site of filterforsiteGrouplist">
                <mat-icon [ngStyle]="{'color':(site.dynamicGroupId ==null)?'red':'green'}"
                  class="m-r">group</mat-icon>{{site.siteGroupName|titlecase}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="selectedMgmt == 'contacts'">
      <div class="row justify-content-end">
        <div class="col-11">
          <!-- selectid  -->
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>{{"Contacts"|translate}}</mat-label>
            <mat-select  multiple formControlName="selectid">
              <mat-select-filter [placeholder]="'Filter'" [displayMember]="'firstName'" [array]="filterforcontactlist"
                (filteredReturn)="filterforcontactlist=$event"></mat-select-filter>
              <mat-option [value]="user.id" *ngFor="let user of filterforcontactlist">
                <img style="border-radius: 50%; margin-right: 6px;" width="30" height="30"
                  [src]="user?.profilePicture ? user.profilePicture:'assets/avatar.svg'"
                  onError="this.src='assets/avatar.svg'">
                {{user.firstName|titlecase}} {{lastName|titlecase}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!-- select Group  -->
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>{{"Contact Groups"|translate}}</mat-label>
            <mat-select  multiple formControlName="selectGroup">
              <mat-select-filter [placeholder]="'Filter'" [displayMember]="'firstName'"
                [array]="filterforcontactGrouplist"
                (filteredReturn)="filterforcontactGrouplist =$event"></mat-select-filter>
              <mat-option [value]="user.id" *ngFor="let user of filterforcontactGrouplist">
                <mat-icon [ngStyle]="{'color':(user.dynamicGroupId ==null)?'red':'blue'}"
                  class="m-r">group</mat-icon>{{user.contactGroupName|titlecase}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="selectedMgmt == 'Products'">
      <div class="row justify-content-end">
        <div class="col-11">
          <!-- selectid  -->
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>{{'Products'|translate}}</mat-label>
            <mat-select  multiple formControlName="selectid">
              <mat-select-filter [placeholder]="'Filter'" [displayMember]="'firstName'" [array]="filterforProsuctlist"
                (filteredReturn)="filterforProsuctlist=$event"></mat-select-filter>
              <mat-option [value]="Product.id" *ngFor="let Product of filterforProsuctlist">
                <img style="border-radius: 50%; margin-right: 6px;" width="30" height="30"
                  [src]="Product?.profilePicture ? Product.profilePicture:'assets/images/iconsForsvg/icon_product.svg '"
                  onError="this.src='assets/images/iconsForsvg/icon_product.svg '">
                {{Product.productName|titlecase}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!-- select Group  -->
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>{{"Products Groups"|translate}}</mat-label>
            <mat-select  multiple formControlName="selectGroup">
              <mat-select-filter [placeholder]="'Filter'" [displayMember]="'firstName'"
                [array]="filterforProsucGrouptlist"
                (filteredReturn)="filterforProsucGrouptlist =$event"></mat-select-filter>
              <mat-option [value]="Product.id" *ngFor="let Product of filterforProsucGrouptlist">
                <mat-icon [ngStyle]="{'color':(Product.dynamicGroupId ==null)?'red':'blue'}"
                  class="m-r">group</mat-icon>{{Product.productGroupName|titlecase}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="selectedMgmt == 'Orders'">
      <div class="row justify-content-end">
        <div class="col-11">
          <!-- selectid  -->
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>{{"Orders"|translate}}</mat-label>
            <mat-select  multiple formControlName="selectid">
              <mat-select-filter [placeholder]="'Filter'" [displayMember]="'firstName'"
                [array]="filterfortransactionlist"
                (filteredReturn)="filterfortransactionlist=$event"></mat-select-filter>
              <mat-option [value]="Transaction.id" *ngFor="let Transaction of filterfortransactionlist">
                {{Transaction.id}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="selectedMgmt == 'Events'">
      <div class="row justify-content-end">
        <div class="col-11">
          <!-- selectid  -->
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>{{"Events"|translate}}</mat-label>
            <mat-select  multiple formControlName="selectid">
              <mat-select-filter [placeholder]="'Filter'" [displayMember]="'firstName'" [array]="filterforeventslist"
                (filteredReturn)="filterforeventslist=$event"></mat-select-filter>
              <mat-option [value]="Event.id" *ngFor="let Event of filterforeventslist">
                {{Event.title|titlecase}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </ng-container>

    <div class="row firstAndLastName">
      <div class="col-6">
        <mat-form-field appearance="legacy" class="w-100">
          <mat-label>{{"Start Date"|translate}}</mat-label>
          <input matInput [matDatepicker]="startdatepicker1" formControlName="startDate" />
          <mat-datepicker-toggle matSuffix [for]="startdatepicker1"></mat-datepicker-toggle>
          <mat-datepicker #startdatepicker1></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field appearance="legacy" class="w-100">
          <mat-label>{{"End Date"|translate}}</mat-label>
          <input matInput [matDatepicker]="enddatepicker"  formControlName="endDate" />
          <mat-datepicker-toggle matSuffix [for]="enddatepicker"></mat-datepicker-toggle>
          <mat-datepicker #enddatepicker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <!-- Keywords  -->
      <mat-form-field class="example-chip-list">
        <mat-label>{{"keyword"|translate}}</mat-label><br>
        <mat-chip-list #chipGridforkeyword aria-label="Fruit selection" style="margin-top: 10px !important;">
          <mat-chip-row style="margin-top:5px !important; margin-bottom:6px !important;"
            *ngFor="let keyword of keywordlist">
            <button style="border: none; border-radius:20px; background-color: rgb(228, 223, 223);">
              <span style="position: relative;left: 3px;">{{keyword|titlecase}}</span>
              <mat-icon
                style="cursor: pointer; position: relative; top:2px;left: 7px; font-size: 20px; color: rgb(158, 154, 154);"
                (click)="remove(keyword)">cancel</mat-icon></button>
          </mat-chip-row>
        </mat-chip-list>
        <input formControlName="keyword" [matChipInputFor]="chipGridforkeyword"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="add($event)" />
      </mat-form-field>
    </div>

  </form>
</mat-dialog-content>
<mat-dialog-actions class="my-3 mb-3 mt-3">
  <div class="row justify-content-end">
    <div class="col-3">
      <button mat-button mat-dialog-close>{{"Cancel"|translate}}</button>
    </div>
    <div class="col-3">
      <button mat-raised-button class="b-blue" (click)="submit()">{{"Apply"|translate}} </button>
    </div>
  </div>
</mat-dialog-actions>