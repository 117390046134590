<div class="loginbody">
    <div>
        <mat-card class="login-card mat-elevation-z8 p-4 ">
            <mat-card-content>
                <form [formGroup]="loginForm">
                    <div class="d-flex justify-content-center mb-2">
                        <img src="assets/logo/login-logo.png" />
                    </div>
                    <!-- New Password  -->
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-icon matPrefix>lock</mat-icon>
                        <mat-label>{{'Password'|translate}} *</mat-label>
                        <input matInput [type]="fieldTextType=='text'? 'text':'password'"
                            formControlName="password">
                        <mat-icon *ngIf="fieldTextType=='password'" (click)="showpassword('off')"
                            matSuffix>visibility_off</mat-icon>
                        <mat-icon *ngIf="fieldTextType=='text'" (click)="showpassword('on')"
                            matSuffix>visibility</mat-icon>
                        <div *ngIf="loginForm.get('password').touched||validatePassword()==false||loginForm.get('password').invalid">
                            <mat-error *ngIf="loginForm.get('password').touched&&loginForm.get('password').errors?.required&&loginForm.get('password').invalid">
                                {{"Password is required"|translate}}
                            </mat-error>
                            <mat-error *ngIf="loginForm.get('password').touched&&loginForm.get('password').errors?.minlength">
                                {{"Password must be at least 8 characters long."|translate}}
                            </mat-error>
                            <mat-error *ngIf="loginForm.get('password').touched&&validatePassword()==false">
                                {{"Password must contain at least one number, one alphabet character, and one special character."|translate}}(e.g., P@ssw0rd).
                            </mat-error>
                        </div>
                    </mat-form-field>

                    <!-- Confirm Password  -->
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-icon matPrefix>lock</mat-icon>
                        <mat-label>{{'Confirm Password'|translate}}</mat-label>
                        <input matInput [type]="fieldTextType=='text'? 'text':'password'"
                            formControlName="confirmPassword">
                        <mat-icon *ngIf="fieldTextType=='password'" (click)="showpassword('off')"
                            matSuffix>visibility_off</mat-icon>
                        <mat-icon *ngIf="fieldTextType=='text'" (click)="showpassword('on')"
                            matSuffix>visibility</mat-icon>
                        <div
                            *ngIf="loginForm.get('confirmPassword').invalid && loginForm.get('confirmPassword').touched">
                            <mat-error *ngIf="loginForm.get('confirmPassword').errors?.required">
                                {{"ConfirmPassword is required"|translate}}
                            </mat-error>
                        </div>
                        <div *ngIf="loginForm.get('confirmPassword').touched">
                            <mat-error *ngIf="getpasswordmismatch()">
                                {{"Passwords do not match"|translate}}
                            </mat-error>
                        </div>
                    </mat-form-field>
                </form>
            </mat-card-content>
            <mat-card-actions>
                <button mat-raised-button [disabled]="!loginForm.valid" (click)="onSubmit()"
                    [ngClass]="loginForm.valid==true? 'b-blue' : 'g-blue'" class="w-100">{{'Apply'|translate}}</button>
                <h2 (click)="backtologin()"
                    style="text-align: center !important;margin-top:15px;color: blue;cursor: pointer;">{{"Back To Login"|translate}}
                </h2>
            </mat-card-actions>
        </mat-card>
    </div>
</div>