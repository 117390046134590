import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-single-and-group-for-dropdown-selaction',
  templateUrl: './single-and-group-for-dropdown-selaction.component.html',
  styleUrls: ['./single-and-group-for-dropdown-selaction.component.scss']
})
export class SingleAndGroupForDropdownSelactionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
