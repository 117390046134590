<div class="d-flex flex-row align-items-center modal-header">
    <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">
        <mat-icon style="position: relative;right: 10px;">
            filter_list</mat-icon>{{'Filter'|translate}}
    </h2>
    <mat-icon mat-dialog-close>close</mat-icon>

</div>

    <mat-dialog-content class="mr-0 ml-0">
        <form [formGroup]="filterForm" id="filter-form" class="filter-form">
        <div class="row pl-2 mb-4">
            <div class="col-md-12 col-sm-12">
                <p>{{'Choose'|translate}}</p> 
                <mat-radio-group id="managementTypeId" formControlName="managementTypeId"
                    (change)="checkentity($event.value)">
                    <mat-radio-button value="Events" class="mr-4" *ngIf="!isCarte">{{'Events'|translate}}</mat-radio-button>
                    <mat-radio-button value="Users" class="mr-4">{{'Users'|translate}}</mat-radio-button>
                    <mat-radio-button value="Contacts" class="mr-4">{{'Contacts'|translate}}</mat-radio-button>
                    <mat-radio-button value="Sites" class="mr-4">{{'Sites'|translate}}</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        <div class="filterbody">
            <!-- ---------------------- 00000000000000000000000 -->
            <ng-container *ngIf="selectedMgmt === 'Events'">
                <div class="row mt-2 mb-2">
                    <div class="row mt-2">
                        <div class="col-6">
                            <mat-list-item>
                                <mat-checkbox formControlName="IsPrivate">
                                    <mat-icon>lock</mat-icon>
                                    {{'Private'|translate}}</mat-checkbox>
                            </mat-list-item>
                        </div>
                        <div class="col-6">
                            <mat-list-item>
                                <mat-checkbox formControlName="IsPublic">
                                    <img src="../../../../assets/images/iconsForsvg/Unlock.svg">
                                    {{'Public'|translate}}</mat-checkbox>
                            </mat-list-item>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-6">
                            <mat-list-item>
                                <mat-checkbox formControlName="IsOnlineEvent">
                                    <img src="../../../../assets/images/iconsForsvg/phone-call.svg" alt="">
                                    {{"Online"|translate}}</mat-checkbox>
                            </mat-list-item>
                        </div>
                        <div class="col-6">
                            <mat-list-item>
                                <mat-checkbox formControlName="IsOfflineEvent">
                                    <img src="../../../../assets/images/iconsForsvg/handshake.svg" alt="">
                                    {{'Off-line'|translate}}</mat-checkbox>
                            </mat-list-item>
                        </div>
                    </div>

                </div>

                <!-- Events filter -->

                <!-- title  -->
                <div class="row">
                    <mat-form-field class="w-100" appearance="legacy">
                        <mat-label>{{'Title'|translate}}</mat-label>
                        <input matInput type="text" placeholder="Title" formControlName="eventsTitle">
                    </mat-form-field>
                </div>
                <!-- Categories  -->
                <div class="row mt-2">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>{{"Categories"|translate}}</mat-label>
                        <mat-select id="CategoryId" formControlName="CategoryId">
                            <mat-option [value]="category.id" *ngFor="let category of categories$">
                                {{category.categoryName|titlecase}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>


                <!-- Critic level  -->
                <div class="row">
                    <mat-form-field class="w-100" appearance="fill">
                        <mat-label>{{'Critic level'|translate}}</mat-label>
                        <mat-select id="CriticId" formControlName="eventsCriticLevel">
                            <mat-option *ngFor="let category of criticals" [value]="category.criticityId">
                                {{category.criticityName|titlecase}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <!-- auto chip Keywords  -->
                <div class="row">
                    <mat-form-field class="example-chip-list" appearance="fill">
                        <mat-label>{{'keyword'|translate}}</mat-label>
                        <mat-chip-list #chipGridforkeyword aria-label="Fruit selection"
                            style="margin-top: 10px !important;">
                            <mat-chip-row style="margin-top:5px !important; margin-bottom:6px !important;"
                                *ngFor="let keyword of keywordlist">
                                <button
                                    style="border: none; border-radius:20px; background-color: rgb(228, 223, 223);padding: 3px 10px;">
                                    <span>{{keyword}}</span>
                                    <mat-icon
                                        style="cursor: pointer; position: relative;left: 5px; font-size: 20px; color: rgb(158, 154, 154);"
                                        (click)="remove(keyword,'keyword')">cancel</mat-icon></button>
                            </mat-chip-row>
                        </mat-chip-list>
                        <input  formControlName="keyword" [matChipInputFor]="chipGridforkeyword"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            (matChipInputTokenEnd)="add($event,'keyword')" />
                    </mat-form-field>
                </div>
            </ng-container>
            <!-- ------------------  user 0000000000001  -->
            <!-- Users filter -->
            <ng-container *ngIf="selectedMgmt === 'Users'">
                <!-- Users  -->
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>{{'Users'|translate}}</mat-label>
                    <mat-select placeholder="Users" multiple id="selactedId" formControlName="selactedId">
                        <mat-select-filter [placeholder]="'Filter'" [displayMember]="'firstName'" [array]="users$"
                            (filteredReturn)="filteredList1 =$event"></mat-select-filter>
                        <mat-option #alluserSelected (click)="SelectionAllUser()" [value]="0"> All</mat-option>
                        <mat-option [value]="user.id" *ngFor="let user of filteredList1">
                            <span>
                                <img style="border-radius: 50%;" width="30" height="30"
                                    [src]="user?.profilePicture ? user.profilePicture:'assets/avatar.svg'"
                                    onError="this.src='assets/avatar.svg'">
                            </span> {{user.firstName|titlecase}} {{user.lastName|titlecase}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <!-- User Group Name  -->
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>{{'User Group'|translate}}</mat-label>
                    <mat-select id="userGroupName" formControlName="GroupsId" multiple>
                        <mat-select-filter [placeholder]="'Filter'" [displayMember]="'userGroupName'"
                            [array]="userGroups$" (filteredReturn)="filteredList4 =$event"></mat-select-filter>
                        <mat-option #allusergroupSelected (click)="SelectionAllUserGroup()" [value]="0">
                            {{'All'|translate}}</mat-option>
                        <mat-option [value]="userGroup.id" *ngFor="let userGroup of filteredList4">
                            <mat-icon
                                [ngStyle]="{'color':(userGroup.dynamicGroupId ==null)?'red':'blue'}">group</mat-icon>
                            {{userGroup.userGroupName|titlecase}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>
            <!-- contact -------------0000000000002  -->
            <!-- Contacts filter -->
            <ng-container *ngIf="selectedMgmt === 'Contacts'">
                <!-- Contact  -->
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>{{'Contact'|translate}}</mat-label>
                    <mat-select placeholder="Contact" multiple id="selactedId" formControlName="selactedId">
                        <mat-select-filter [placeholder]="'Filter'" [displayMember]="'firstName'" [array]="contacts$"
                            (filteredReturn)="filteredList2 =$event"></mat-select-filter>
                        <mat-option #allContactSelected (click)="SelectionAllContact()" [value]="0">
                            {{'All'|translate}}</mat-option>
                        <mat-option [value]="contact.id" *ngFor="let contact of filteredList2">
                            <img style="border-radius: 50%;" width="30" height="30"
                                [src]="contact?.profilePicture ? contact.profilePicture:'assets/avatar.svg'"
                                onError="this.src='assets/avatar.svg'">
                            {{contact.firstName|titlecase}} {{contact.lastName|titlecase}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <!-- Contact Group Name  -->
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>{{'Contact Group'|translate}} </mat-label>
                    <mat-select id="contactGroupName" formControlName="GroupsId" multiple>
                        <mat-select-filter [placeholder]="'Filter'" [displayMember]="'contactGroupName'"
                            [array]="contactGroups$" (filteredReturn)="filteredList5 =$event"></mat-select-filter>
                        <mat-option #allContactgroupSelected (click)="SelectionAllContactGroup()" [value]="0">
                            {{'All'|translate}}</mat-option>
                        <mat-option [value]="contactGroup.id" *ngFor="let contactGroup of filteredList5">
                            <mat-icon
                                [ngStyle]="{'color':(contactGroup.dynamicGroupId ==null)?'red':'blue'}">group</mat-icon>
                            {{contactGroup.contactGroupName|titlecase}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>
            <!-- sites -------------- 00003  -->
            <ng-container *ngIf="selectedMgmt === 'Sites'">
                <!-- Sites filter -->
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>{{'Sites'|translate}}</mat-label>
                    <mat-select placeholder="Sites" multiple id="selactedId" formControlName="selactedId">
                        <mat-select-filter [placeholder]="'Filter'" [displayMember]="'companyName'" [array]="sites$"
                            (filteredReturn)="filteredList3 =$event"></mat-select-filter>
                        <mat-option #allSitesSelected (click)="SelectionAllSites()" [value]="0">
                            {{'All'|translate}}</mat-option>
                        <mat-option [value]="site.id" *ngFor="let site of filteredList3">
                            {{site.companyName|titlecase}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <!-- Site Group Name -->
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>{{'Site Group'|translate}}</mat-label>
                    <mat-select id="siteGroupName" formControlName="GroupsId" multiple>
                        <mat-select-filter [placeholder]="'Filter'" [displayMember]="'siteGroupName'"
                            [array]="siteGroups$" (filteredReturn)="filteredList6 =$event"></mat-select-filter>
                        <mat-option #allSitegroupSelected (click)="SelectionAllSiteGroup()" [value]="0">
                            {{'All'|translate}}</mat-option>
                        <mat-option [value]="siteGroup.id" *ngFor="let siteGroup of filteredList6">
                            <mat-icon
                                [ngStyle]="{'color':(siteGroup.dynamicGroupId ==null)?'red':'blue'}">group</mat-icon>
                            {{siteGroup.siteGroupName|titlecase}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>

            <!-- Products filter -->
            <ng-container *ngIf="selectedMgmt === 'products'">
                <mat-form-field class="w-100" appearance="legacy">
                    <mat-label>{{'Name'|translate}}</mat-label>
                    <input matInput type="text" placeholder="Name" formControlName="name">
                </mat-form-field>

                <mat-form-field class="w-100" appearance="legacy">
                    <mat-label>{{'Groupe'|translate}}</mat-label>
                    <input matInput type="text" placeholder="Groupe" formControlName="groupe">
                </mat-form-field>

            </ng-container>

            <!-- Common for Users, Sites, Contacts informations -->
            <ng-container *ngIf="selectedMgmt === 'Users' || selectedMgmt === 'Sites' || selectedMgmt === 'Contacts'">
                <!-- auto chip for city  -->
                <mat-form-field class="example-chip-list" appearance="fill">
                    <mat-label>{{'city'|translate}}</mat-label>
                    <mat-chip-list #chipGridforcity aria-label="Fruit selection" style="margin-top: 10px !important;">
                        <mat-chip-row style="margin-top:5px !important; margin-bottom:6px !important;"
                            *ngFor="let city of citylist">
                            <span
                                style="padding: 5px; background-color: #d3c7c7; margin:4px; border-radius: 5px solid red !important;">{{city}}
                                <mat-icon style="cursor: pointer; position: relative;"
                                    (click)="remove(city,'city')">cancel</mat-icon></span>
                        </mat-chip-row>
                    </mat-chip-list>
                    <input  formControlName="city" [matChipInputFor]="chipGridforcity"
                        [matAutocomplete]="autoforcity" [matChipInputSeparatorKeyCodes]="separatorcityCodes"
                        (matChipInputTokenEnd)="add($event,'city' )" (ngModelChange)="filtercity($event)" />
                    <mat-autocomplete #autoforcity="matAutocomplete" (optionSelected)="selected($event,'city')">
                        <mat-option *ngFor="let city of CityApiList" [value]="city">
                            {{city}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <!-- ---------------  -->
                <!-- auto chip for Country  -->
                <mat-form-field class="example-chip-list" appearance="fill">
                    <mat-label>{{'Country'|translate}}</mat-label>
                    <mat-chip-list #chipGridforcountry aria-label="Fruit selection"
                        style="margin-top: 10px !important;">
                        <mat-chip-row style="margin-top:5px !important; margin-bottom:6px !important;"
                            *ngFor="let country of countrylist">
                            <span
                                style="padding: 5px; background-color: #d3c7c7; margin:4px; border-radius: 5px solid red !important;">{{country}}
                                <mat-icon style="cursor: pointer; position: relative;"
                                    (click)="remove(country,'country')">cancel</mat-icon></span>
                        </mat-chip-row>
                    </mat-chip-list>
                    <input  formControlName="country" [matChipInputFor]="chipGridforcountry"
                        [matAutocomplete]="autoforcountry" [matChipInputSeparatorKeyCodes]="separatorcountryCodes"
                        (matChipInputTokenEnd)="add($event,'country')" (ngModelChange)="filtercountry($event)" />
                    <mat-autocomplete #autoforcountry="matAutocomplete" (optionSelected)="selected($event,'country')">
                        <mat-option *ngFor="let country of countryApiList" [value]="country">
                            {{country}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <!-- -----------  -->
                <!-- auto chip for zipcode  -->
                <mat-form-field class="example-chip-list" appearance="fill">
                    <mat-label>{{'zipCode'|translate}}</mat-label>
                    <mat-chip-list #chipGridforzipCode aria-label="Fruit selection"
                        style="margin-top: 10px !important;">
                        <mat-chip-row style="margin-top:5px !important; margin-bottom:6px !important;"
                            *ngFor="let zipCode of zipCodelist">
                            <span
                                style="padding: 5px; background-color: #d3c7c7; margin:4px; border-radius: 5px solid red !important;">{{zipCode}}
                                <mat-icon style="cursor: pointer; position: relative;"
                                    (click)="remove(zipCode,'zipCode')">cancel</mat-icon></span>
                        </mat-chip-row>
                    </mat-chip-list>
                    <input  formControlName="zipCode" [matChipInputFor]="chipGridforzipCode"
                        [matAutocomplete]="autoforzipCode" [matChipInputSeparatorKeyCodes]="separatorzipCodeCodes"
                        (matChipInputTokenEnd)="add($event,'country')" (ngModelChange)="filterzipcode($event)" />
                    <mat-autocomplete #autoforzipCode="matAutocomplete" (optionSelected)="selected($event,'zipCode')">
                        <mat-option *ngFor="let zipCode of zipCodeApiList" [value]="zipCode">
                            {{zipCode}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <!-- -----------  -->
                <!-- auto chip Keywords  -->
                <mat-form-field class="example-chip-list" appearance="fill">
                    <mat-label>{{'keyword'|translate}}</mat-label>
                    <mat-chip-list #chipGridforkeyword aria-label="Fruit selection"
                        style="margin-top: 10px !important;">
                        <mat-chip-row style="margin-top:5px !important; margin-bottom:6px !important;"
                            *ngFor="let keyword of keywordlist">
                            <button style="border: none; border-radius:20px; background-color: rgb(228, 223, 223);">
                                <span style="position: relative;">{{keyword}}</span>
                                <mat-icon
                                    style="cursor: pointer; position: relative; top:2px;left: 7px; font-size: 20px; color: rgb(158, 154, 154);"
                                    (click)="remove(keyword,'keyword')">cancel</mat-icon></button>
                        </mat-chip-row>
                    </mat-chip-list>
                    <input  formControlName="keyword" [matChipInputFor]="chipGridforkeyword"
                        [matAutocomplete]="autoforkeyword" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        (matChipInputTokenEnd)="add($event,'keyword')" />
                    <mat-autocomplete #autoforkeyword="matAutocomplete" (optionSelected)="selected($event,'keyword')">
                        <mat-option *ngFor="let keyword of keywordApiList" [value]="keyword">
                            {{keyword}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>

            </ng-container>
        </div>

 
        <ng-container *ngIf="selectedMgmt === 'Sites' || selectedMgmt === 'Contacts' ||selectedMgmt === 'Users'">
            <div class="TargetBase" style="margin-top: 10px !important;">
                <div class="justify-content-center">
                    <h3 style="text-align: center; color: white; background-color:#f44336; margin:0 !important;">
                        {{'Target Based'|translate}}</h3>
                </div>
                <mat-radio-group class="example-radio-group p-2" formControlName="targetFilter">
                    <mat-radio-button value="nodisplay" class="mr-4 mt-2">{{'No Display Target Option'|translate}}</mat-radio-button>
                    <mat-radio-button value="target" class="mr-4 mt-2">{{'Display With Target Only'|translate}} </mat-radio-button>
                    <span *ngIf="checkFilter()" class="example-radio-group targetMargin">
                        <mat-checkbox formControlName="Monthlytarget" class=" fw-600" value="monthly"
                            class="example-margin"> <span class=" fw-600">{{'Monthly'|translate}} </span>({{data.startdate |
                            date:'MMMM'}})
                        </mat-checkbox>
                        <mat-checkbox formControlName="Yearlytarget" class=" fw-600" value="yearly"
                            class="example-margin"> <span class=" fw-600">{{'Yearly'|translate}} </span>({{data.startdate |
                            date:'yyyy'}})
                        </mat-checkbox>
                    </span>
                    <mat-radio-group *ngIf="checkFilter()" class="example-radio-group acheivedMargin"
                        formControlName="acheiveFilter">
                        <mat-radio-button value="acheive" class="mr-4">{{'Achieved'|translate}}</mat-radio-button>
                        <mat-radio-button value="notacheive" class="mr-4">{{'Not Achieved'|translate}}</mat-radio-button>
                    </mat-radio-group>
                    <mat-radio-button value="notarget" class="mr-4 mt-2">{{'Display with no Target only'|translate}}</mat-radio-button>
                </mat-radio-group>
            </div>
        </ng-container>
    </form>
    </mat-dialog-content>
    <mat-dialog-actions align="end" class="my-1">
        <div class="row mt-2 justify-content-center">
            <div class="col-4">
                <button mat-raised-button mat-dialog-close  class="W-blue" >{{"Cancel"|translate}}</button>
            </div>
            <div class="col-4">
                <button mat-raised-button class="b-blue" (click)="fetchData()">{{'Filter'|translate}}</button>
            </div>
        </div>
    </mat-dialog-actions>
