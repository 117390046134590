<header>
    <div class="d-flex flex-row align-items-center modal-header">
        <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">Add New Tag</h2>
        <mat-icon mat-dialog-close>close</mat-icon>
    </div>
</header>

<form [formGroup]="productForm">
    <mat-card>
        <mat-card-content class="mr-0 ml-0">
            <div class="row">
                <mat-radio-group class="example-radio-group" aria-label="Select an option" formControlName="tagType"
                    (click)="onChanges()">
                    <mat-radio-button class="example-radio-button" value="Checkbox">Check box</mat-radio-button>
                    <mat-radio-button class="example-radio-button" value="Dropdown">Dropdown list</mat-radio-button>
                    <mat-radio-button class="example-radio-button" value="Freefeild">Free feild</mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="row" *ngIf="selectedradioval == 'Freefeild'">
                <mat-radio-group class="example-radio-group" aria-label="Select an option" formControlName="numeralType"
                    (click)="checkChanges()">
                    <mat-radio-button class="example-radio-button" checked="true" value="Alphanumeric">Alphanumeric
                    </mat-radio-button>
                    <mat-radio-button class="example-radio-button" value="Numerals">Numerals Only</mat-radio-button>
                </mat-radio-group>
            </div>

            <div class="row justify-content-start" *ngIf="selectedradioval != 'Dropdown'">
                <div class="col-12">
                    <mat-checkbox formControlName="isMandatory">{{"Mandatory"|translate}}</mat-checkbox>
                </div><br>
                <div class="col-8">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>Title </mat-label>
                        <input matInput type="text" formControlName="title">
                    </mat-form-field>
                </div>
            </div>
            <div class="row justify-content-start" *ngIf="selectedradioval == 'Dropdown'">
                <div class="col-12">
                    <mat-checkbox formControlName="isMandatory">{{"Mandatory"|translate}}</mat-checkbox>
                </div><br>
                <div class="col-8">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>Title </mat-label>
                        <input matInput type="text" formControlName="title">
                    </mat-form-field>
                </div>
                <div class="col-4">
                    <button mat-raised-button class="b-blue" (click)="addcreateBody()">Add value</button>
                </div>
                <div class="col-12">
                    <div class="row" formArrayName="Adddropdownvalue">
                        <div class="row" *ngFor="let conditionsBody of getfileBody().controls; let i=index"
                            [formGroupName]="i">
                            <div class="row">
                                <div class="col-11 p1">
                                    <mat-form-field class="w-100">
                                        <input matInput placeholder="value" formControlName="dropdownvalue">
                                    </mat-form-field>
                                </div>
                                <div *ngIf="i!=0" class="col-1">
                                    <mat-icon (click)="removeConditionalBody(i)">delete</mat-icon>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </mat-card-content>
        <mat-card-actions>
            <div class="row">
                <div class="col-6"></div>
                <div class="col-6">
                    <button mat-button mat-dialog-close (click)="close()">Cancel</button>
                    <button mat-raised-button class="b-blue" (click)="applytag()">Create</button>
                </div>
            </div>
        </mat-card-actions>
    </mat-card>
</form>