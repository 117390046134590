<div class="d-flex flex-row align-items-center modal-header">
    <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">{{"Choose Share Option"|translate}}</h2>
    <mat-icon mat-dialog-close>close</mat-icon>
  </div>
  
  
    <div class="container">
      <div class="row justify-content-start">
          <div class="col-12 p-2">
              <button class="fw-600" mat-menu-item (click)="radioChange('Link')">
                <mat-icon style="color: rgb(52, 34, 217);"> link</mat-icon>
                Share Link
              </button>
              <button class="fw-600" mat-menu-item (click)="radioChange('Drive')">
                <mat-icon style="color: rgb(64, 15, 226);"> import_export</mat-icon> Export Drive
              </button>
          </div>
      </div>
  </div>
 