<div class="d-flex flex-row align-items-center modal-header">
    <mat-icon mat-dialog-close (click)="closedialogbox()">keyboard_backspace</mat-icon>
    <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">{{"Card Details"|translate}}</h2>
    <mat-icon mat-dialog-close (click)="closedialogbox()">close</mat-icon>
</div>
<mat-dialog-content class="mr-0 ml-0">
    <div class="row mt-2 justify-content-center">
        <h4>{{"Enter Card Details"|translate}}</h4>
    </div>
    <form #paymentForm="ngForm">
        <div column="column">
            <div>
                <div #cardNumInfo id="card-element"></div>
                <div #cardNumError id="card-errors"></div>
                <div class="row">
                    <div class="column1">
                        <div #cardExInfo id="card-element"></div>
                        <div #cardExError id="card-errors"></div>
                    </div>
                    <div class="column2">
                        <div #cardCvcInfo id="card-element"></div>
                        <div #cardCvcError id="card-errors"></div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end" class="my-1">   
        <div class="row mt-2 mb-4 justify-content-center" style="margin-bottom: 10px !important;">
            <div class="col-4">
                <button (click)="closedialogbox()" mat-raised-button>{{"Cancel"|translate}}</button>
            </div>
            <div class="col-4">
                <button (click)="getcardtokendetails()" class="b-blue"
                mat-raised-button>{{"Save"|translate}}</button>            </div>
        </div>
</mat-dialog-actions>