<div class="m-0 p-0" >
    <div style="display: flex;margin-top: 10px;">
        <img style="margin: auto;" src="../../assets/logo/logo.png" class="imagestyle" >
    </div>
    <div  style="display: flex;margin-top: 10px;">
        <img class="imageiconstyle" src="../../../../assets/notecreateicons/cancel.svg">
    </div>
    <p>
        {{"Oops! Something went wrong. Click here to resend the verification email or contact us for help."|translate}}
    </p>
</div>