<div class="User-details">
  <div class="d-flex flex-row align-items-center modal-header1">
    <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">
      {{'Activity Log'|translate}}
    </h2>
    <mat-icon (click)="opensearch()">filter_list</mat-icon>
    <mat-icon mat-dialog-close class="mx-4">close</mat-icon>
  </div>
  <mat-dialog-content class="mr-0 ml-0">
    <div class="row mt-2" *ngIf="ActivitLogdata.length !=0">
      <h2 style="margin-right: 3px;"> Activity Log For </h2>
      <img class="avatar" style="border-radius: 50%;"
        *ngIf="data.data?.profilePicture != '' || data.data?.profilePicture != null"
        [src]="data.data?.profilePicture">
      <span
        *ngIf="((data.data?.profilePicture == ''|| data.data?.profilePicture == null) && (data.type == 'Users' || data.type == 'Contacts'))"
        [style.background]="getColor(4)"
        style="color: white; padding: 8px;border-radius: 50%;position: relative; top: -8px;margin: 5px">
        {{getuserFLnames(data.data.firstName+' '+data.data.lastName)}}
      </span>
      <span *ngIf="data.type == 'Sites'">
        <img style="width: 30px; height: 30px;" src="../../../../../assets/images/iconsForsvg/Site_Icon.svg">
      </span>
      <span *ngIf="(data.type == 'Products')">
        <img style="width: 30px; height: 30px;"
          [src]="data.data?.profilePicture ? data.data?.profilePicture : '../../../../../assets/images/iconsForsvg/icon_product.svg'">
      </span>
      <mat-icon *ngIf="this.data.type == 'Transactions'" style="color:rgb(100, 149, 237)"> shopping_cart</mat-icon>
      <mat-icon *ngIf="this.data.type == 'Orders'" style="color:rgb(100, 149, 237)"> assignment</mat-icon>
      <mat-icon *ngIf="this.data.type == 'Tasks' && this.data.data.isMileStone == false" style="color:green">
        assignment_turned_in</mat-icon>
      <mat-icon *ngIf="this.data.type == 'Initiatives'" style="color:red"> ballot</mat-icon>
      <span *ngIf="this.data.type == 'Tasks' && this.data.data.isMileStone == true">
        <img style="width: 30px; height: 30px;"
          src="../../../../assets/images/iconsForsvg/nearby_FILL0_wght400_GRAD0_opsz24.svg">

      </span>
      <span *ngIf="this.data.type == 'Automation'">
        <img style="width: 30px; height: 30px;" src="../../../../assets/images/iconsForsvg/setting-automation.svg">
      </span>
      <h2 *ngIf="this.data.type == 'Users' || this.data.type == 'Contacts'">{{data.data.firstName|titlecase}}
        {{data.data.lastName|titlecase}}</h2>
      <h2 *ngIf="this.data.type == 'Sites'">{{data.data.companyName|titlecase}}</h2>
      <h2 *ngIf="this.data.type == 'Products'">{{data.data.productName|titlecase}}</h2>
      <h2 *ngIf="this.data.type == 'Transactions'">{{data.data.transactionReference}}</h2>
      <h2 *ngIf="this.data.type == 'Orders'">{{data.data.orderId}}</h2>
      <h2 *ngIf="this.data.type == 'Tasks'">{{data.data.title}}</h2>
      <h2 *ngIf="this.data.type == 'Initiatives'">{{data.data.title}}</h2>
      <h2 *ngIf="this.data.type == 'Automation'">{{data.data.name}}</h2>
    </div>
    <ng-container *ngIf="ActivitLogdata.length ==0 || ActivitLogdata.length == ''">
      <div class="row mt-5">
        <div class="col-12 p-0 nodata_text">
          <span>No Activity Logs for this {{data.Entity == 'User' ? 'User' : data.Entity == 'Contacts' ? 'Contact' :
            data.Entity == 'Sites' ? 'Site' : data.Entity == 'Product' ? 'Product' : data.Entity == 'Transaction' ?
            'Transaction' : data.Entity == 'Task' ? 'Task' : 'Initiative'}}
            <img
              *ngIf="((data.data?.profilePicture !=null || data.data?.profilePicture != '' || data.data.profile != null || data.data?.profile != '') && (data.type == 'Users' || data.type == 'Contacts' || data.type == 'Products'))"
              class="avatar" style="border-radius: 50%;margin-top: -8px;"
              [src]="data.data?.profilePicture ? data.data?.profilePicture : data.data?.profile">
            <span
              *ngIf="((data.data?.profilePicture == ''||data.data?.profilePicture == null) && (data.type == 'Users' || data.type == 'Contacts'))"
              [style.background]="getColor(4)"
              style="color: white; padding: 8px;border-radius: 50%;position: relative; top: -8px;margin: 5px">
              {{getuserFLnames(data.data.firstName+' '+data.data.lastName)}}
            </span>
            <span *ngIf="data.type == 'Sites'">
              <img style="width: 30px; height: 30px;" src="../../../../../assets/images/iconsForsvg/Site_Icon.svg">
            </span>
            <span *ngIf="((data.type == 'Products') && (data.data.profile == '' || data.data.profile == null))">
              <img style="width: 30px; height: 30px;"
                [src]="data.data?.profilePicture ? data.data.profilePicture : '../../../../../assets/images/iconsForsvg/icon_product.svg'">
            </span>
            <mat-icon *ngIf="this.data.type == 'Transactions'" style="color:rgb(100, 149, 237)">
              shopping_cart</mat-icon>
            <mat-icon *ngIf="this.data.type == 'Orders'" style="color:rgb(100, 149, 237)"> assignment</mat-icon>
            <mat-icon *ngIf="this.data.type == 'Tasks' && this.data.data.isMileStone == false" style="color:green">
              assignment_turned_in</mat-icon>
            <mat-icon *ngIf="this.data.type == 'Initiatives'" style="color:red"> ballot</mat-icon>
            <span *ngIf="this.data.type == 'Tasks' && this.data.data.isMileStone == true">
              <img style="width: 30px; height: 30px;"
                src="../../../../assets/images/iconsForsvg/nearby_FILL0_wght400_GRAD0_opsz24.svg">

            </span>
            <b *ngIf="this.data.type == 'Users' || this.data.type == 'Contacts'">{{data.data.firstName + ' ' +
              data.data.lastName}}</b>
            <b *ngIf="this.data.type == 'Sites'">{{data.data.companyName|titlecase}}</b>
            <b *ngIf="this.data.type == 'Products'">{{data.data.productName|titlecase}}</b>
            <b *ngIf="this.data.type == 'Transactions'">{{data.data.transactionReference}}</b>
            <b *ngIf="this.data.type == 'Orders'">{{data.data.orderId}}</b>
            <b *ngIf="this.data.type == 'Tasks'">{{data.data.title}}</b>
            <b *ngIf="this.data.type == 'Initiatives'">{{data.data.title}}</b>
          </span>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="ActivitLogdata.length !=0 || ActivitLogdata.length != ''">
      <!-- <div class="row mt-2">
              <div class="col-12 p-0">
                  <p style="text-align: center;">No Data Found</p>
                  </div>
                  </div> -->
      <div *ngFor="let x of ActivitLogdata;let index=index;">
        <div
          style="background-color: #e2f2e6;display: flex;border-bottom: 1px solid black;border-top: 1px solid black;">
          <div style="width:12%;">
            <mat-icon *ngIf="x.entity == 'Task'" style="color:green;padding: 12px;"> assignment_turned_in</mat-icon>
            <mat-icon *ngIf="x.entity == 'User'" style="color:#fb4444;padding: 12px;"> person</mat-icon>
            <mat-icon *ngIf="x.entity == 'Event'" style="color:#fb4444;padding: 12px;"> event</mat-icon>
            <mat-icon *ngIf="x.entity == 'Contacts'" style="color:#6495ed;padding: 12px;"> contacts</mat-icon>
            <img *ngIf="x.entity == 'Sites'" src="../../../../../assets/images/iconsForsvg/Site_Icon.svg"
              style="width: 25px; height: 25px; margin-top: 8px; margin-left: 6px;">
            <mat-icon *ngIf="x.entity == 'Product'" style="color:#fb4444;padding: 12px;"> business_center</mat-icon>
            <mat-icon *ngIf="x.entity == 'Initiative'" style="color:#fb4444;padding: 12px;"> ballot</mat-icon>
            <mat-icon *ngIf="x.entity == 'Transaction' || x.entity == 'Order'" style="color:#6495ed;padding: 12px;">shopping_cart</mat-icon>
            <mat-icon *ngIf="x.entity == 'Note'" style="color:#ffc16f;padding: 12px;">speaker_notes</mat-icon>
          </div>
          <div style="width:50%;display: flex;">
            <span *ngIf="x.profilePicture == null || x.profilePicture == ''" [style.background]="getColor(index)"
              style="color: white;padding: 10px;border-radius: 50%;position: relative;margin: 4px;background: rgb(40, 116, 166);font-size: 14px;">
              {{getuserFLnames(x.title)}}</span>
            <!-- <img *ngIf="x.profilePicture != null || x.profilePicture != ''" class="user_profile"  style="width:40px;height:40px;border-radius:50%;"
                          [src]="x.profilePicture" /> -->
            <p class="user_name" style="font-weight:600;margin-top:12px;font-size: 14px;">{{x.title}}</p>
          </div>
          <div style="width: 35%;">
            <div class="row w-100" style="margin-top:12px">
              <p style="font-size: 12px;margin-right:8px;">{{ x.logTime | date: 'dd-MMM-yyyy hh:mm:ss' }}</p>
            </div>
          </div>
        </div>
        <div class="rich-text mt-2 mb-2" style="margin:10px">
          <p style="font-weight:500">{{x.message}}</p>

        </div>
      </div>
    </ng-container>

  </mat-dialog-content>

  <div class="overlay" *ngIf="spinnerLoader">
    <mat-progress-spinner class="spinner" [color]="color" [mode]="mode" [value]="value">
    </mat-progress-spinner>
  </div>