<div class="d-flex flex-row align-items-center modal-header">
  <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">{{"File And Notes"|translate}}</h2>
  <mat-icon mat-dialog-close>close</mat-icon>
</div>


<ng-container *ngIf="createfileNote">
  <div class="container">
    <div class="row justify-content-start">
        <div class="col-12 p-2">
            <button class="fw-600" mat-menu-item (click)="radioChange(1,'All')">
              <mat-icon style="color:rgb(255,193,111) ;">speaker_notes</mat-icon>{{"Add a Note"|translate}}
            </button>
            <button class="fw-600" mat-menu-item (click)="radioChange(2,'All')">
              <mat-icon style="color:rgb(66,135,245) ;">insert_drive_file</mat-icon>{{"Add a File"|translate}}
            </button>
        </div>
    </div>
</div>
</ng-container>


<ng-container *ngIf="fileNoteForEvent">
  <div class="container">
    <div class="row justify-content-center">
        <div class="col-12 p-3">
            <button class="fw-600" mat-menu-item (click)="radioChange(1,'All')">
              <mat-icon style="color:rgb(255,193,111) ;">speaker_notes</mat-icon>{{"Add a Note"|translate}}
            </button>
            <button class="fw-600" mat-menu-item (click)="radioChange(2,'All')">
              <mat-icon style="color:rgb(66,135,245) ;">insert_drive_file</mat-icon>{{"Add a File"|translate}}
            </button>
            <button class="fw-600 filebutton"  mat-menu-item (click)="skip()">
              {{"Skip"|translate}}
            </button>
        </div>
    </div>
</div>
</ng-container>













