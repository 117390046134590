<div class="d-flex flex-row align-items-center modal-header">
  <button mat-icon-button mat-dialog-close class="back-arrow"><mat-icon>keyboard_backspace</mat-icon></button>
  <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">{{'Group Planning'|translate}}</h2>
</div>
<mat-dialog-content class="planning-list">
    <div class="w-100" >
      <mat-selection-list #groupplanning id="group-planning">
        <mat-list-option *ngFor="let item of [1,2,3,4,5,6,7,8,9]">
          <img src="../../../../assets/images/1.jpg"/>
          <div class="pl-12">
            <div>Manivannan Durairaj</div>
            <div>9944332730</div>
          </div>
        </mat-list-option>
      </mat-selection-list>
    </div>
    <mat-dialog-actions align="end" class="my-1">
        <button mat-button mat-dialog-close>Cancel</button>
        <button (click)="groupEvent()" mat-raised-button cdkFocusInitial color="primary">{{'Apply Filter'|translate}}</button>
    </mat-dialog-actions>
  </mat-dialog-content>
